@import url(/Applications/MAMP/htdocs/claims/src/theme/variables.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;700&display=swap");
html {
  font-size: 10px;
}

body {
  font-size: 20px;
  line-height: 22.5px;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 4300;
  white-space: pre-line;
}

.flag_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
}

.flag_wrapper img {
  cursor: pointer;
  max-width: 20px;
}

h1, .h1, .tyCard h1 {
  font-size: 52px;
  line-height: 22.5px;
  font-size: 5.2rem;
  line-height: 1.5;
  font-weight: 300;
}

h2 {
  font-size: 30px;
  line-height: 22.5px;
  font-size: 3rem;
  line-height: 1.5;
  font-weight: 700;
}

small {
  font-size: 16px;
  line-height: 22.5px;
  font-size: 1.6rem;
  line-height: 1.5;
}

.primary {
  color: var(--ion-color-primary);
}

ion-badge {
  border-radius: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
}

ion-button {
  --border-radius: 50px;
}

ion-item::part(native) {
  background: #ffffff;
}

ion-card {
  --background: #ffffff;
}

.ion-color-secondary {
  --color: --ion-color-primary;
}

.button_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 1rem;
}

.claimItem {
  text-decoration: none;
}

.claimItem .sc-ion-label-md-s h2 {
  font-weight: 700;
}

.login_page ion-item::part(native),
form ion-item::part(native) {
  border-radius: 10px;
  margin: 0.3rem 0;
}

.PhoneInputInput {
  background-color: transparent;
  border: none;
}

.img_button_wrapper {
  margin-top: 5rem;
}

.img_button_wrapper ion-button {
  margin-bottom: 2rem;
}

.main_container,
.login_page {
  max-width: 600px;
  margin: auto;
}

.innerModal_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.ion-text-wrap {
  margin-right: 0.5rem;
}

.countdownCard {
  text-align: center;
}

.countdown {
  font-size: 35px;
}

.textCenter {
  text-align: center;
}

.bigText {
  font-size: 20px !important;
}

.loader_wrapper {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 2222;
}

#phone_number {
  direction: ltr;
}

.otherWrappaer,
.Ribit2Option {
  padding: 0 3rem;
}

.yellow_bg {
  --background: var(--ion-color-secondary);
}

.tyCard {
  border-radius: 10px;
  text-align: center;
}

.tyCard ion-card-title {
  font-size: 30px;
  line-height: 22.5px;
  font-size: 3rem;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 700;
}

.tyCard ion-card-subtitle {
  font-size: 20px;
  line-height: 22.5px;
  font-size: 2rem;
  line-height: 1.5;
}

.tyCard h1 {
  color: var(--ion-color-primary);
}

.tyCard p {
  font-size: 20px;
  line-height: 22.5px;
  font-size: 2rem;
  line-height: 1.5;
}

.thankYouCalendly {
  margin: 2rem 0 !important;
}

.rtl #nextBtn {
  float: left;
}

.rtl #prevBtn {
  float: right;
}
