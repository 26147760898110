#cameraPreviewRef,
#cameraCheckPreview,
#cameraCheckPreview_undefined,
#cameraCheckPreview_debtor,
#cameraPreview {
    position: relative;
    width: 100%;

}

canvas {
    max-width: 100%;
}

.pictureOverlay {
    padding-top: calc((7 / 16) * 100%);
    position: absolute;
    overflow: hidden;
    z-index: 11;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

}

.tazOverlay {
    padding-top: calc((5 / 9) * 100%);
    /* height:auto; */
    /* width:40% */
}

#video {
    width: 100%;
}

.overlay {

    border: 3px dashed red;
    pointer-events: none;


    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    /* display: block; */
    object-fit: cover;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
}

.camera-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    border: 2px dashed red;
    pointer-events: none;
}

.swiper-slide img.base64Img {
    display: block;
    margin: 3rem auto;
    max-width: 60%;
}

/* Without setting height the slides will take up the height of the slide's content */
ion-slides {
    height: 100%;
}

/* .buttonWrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
} */
.buttonWrapper {
    position: relative;
    bottom: 0;
}

#nextBtn {
    float: right;
}

#prevBtn {
    float: left;
}

.swiper-slide,
ion-slides {
    overflow-y: scroll;
    display: block;
}

.claimItem {
    width: 100%;
    display: flex;
    align-items: center;

}

.claimItem>* {
    margin-right: 2rem;
}

.editClaim {
    margin-right: 1rem;
    cursor: pointer
}

/* .header_buttons{
    float: right;
    text-align: right;
    position: relative;
    z-index: 200;
} */