@import "../variables.css";
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;700&display=swap");
@import "./mixins";
html {
	font-size: 10px;
}
body {
	@include font-size(2);
	font-weight: 4300;
	white-space: pre-line;
}
.flag_wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	margin-bottom: 3rem;
	img {
		cursor: pointer;
		max-width: 20px;
	}
}
h1,.h1 {
	@include font-size(5.2);
	font-weight: 300;
}
h2 {
	@include font-size(3);
	font-weight: 700;
}
small {
	@include font-size(1.6);
}
.primary {
	color: var(--ion-color-primary);
}
ion-badge {
	border-radius: 10px;
	--padding-end: 10px;
	--padding-start: 10px;
}
ion-button {
	--border-radius: 50px;
}
ion-item::part(native) {
	background: #ffffff;
}
ion-card {
	--background: #ffffff;
}
.ion-color-secondary {
	--color: --ion-color-primary;
}
.button_wrapper {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-top: 1rem;
}
.claimItem {
	text-decoration: none;

	.sc-ion-label-md-s h2 {
		font-weight: 700;
	}
}
.login_page,
form {
	ion-item::part(native) {
		border-radius: 10px;
		margin: 0.3rem 0;
	}
}
.PhoneInputInput {
	background-color: transparent;
	border: none;
}
.img_button_wrapper {
	margin-top: 5rem;
	ion-button {
		margin-bottom: 2rem;
	}
}
.main_container,
.login_page {
	max-width: 600px;
	margin: auto;
}
// :
.innerModal_buttons{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 2rem;
}
.ion-text-wrap{
	margin-right:0.5rem;
}
.countdownCard{
	text-align: center;
}
.countdown{
	font-size: 35px;
}
.textCenter{
	text-align: center;
}
.bigText{
	font-size: 20px!important;
}
.loader_wrapper{
	position: absolute;
	background: rgba( #ffffff,  0.7);
	width: 100%;
	top:0;
	right:0;
	left:0;
	bottom:0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2222;
}
#phone_number{
	direction: ltr;
}
// dir(rtl) {
// 	.header_buttons {
// 		// float: left;
// 		text-align: left;
// 		position: relative;
// 		z-index: 200;
// 	}
// }
.otherWrappaer,
.Ribit2Option{
	padding:0 3rem;
}
.yellow_bg{
	--background: var(--ion-color-secondary);
}
.tyCard{
	border-radius: 10px;
	text-align: center;
	ion-card-title{
		@include font-size(3);
		margin-bottom: 0;
		font-weight: 700;
	}
	ion-card-subtitle{
		@include font-size(2);
	}
	h1{
		@extend .h1;
		color:var(--ion-color-primary)
	}
	p{
		@include font-size(2);
	}
}
.thankYouCalendly{
	margin:2rem 0!important;
}
.rtl{
	#nextBtn {
		float: left;
	}
	
	#prevBtn {
		float: right;
	}
}